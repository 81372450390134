import { Link, navigate } from "gatsby";
import {
    Box, Image, Text, TextInput
} from "grommet";
import React, { useEffect, useState } from "react";
import PrimaryButton from "../components/elements/primary_button";
import Layout from "../components/layout";
import mainLogo from "../images/logo.avif";
import service from '../service/login_service';
import storageService from '../service/storage_service';

const LoginPage = ({ logo }) => {

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const token = storageService.getToken();
        if (token) {
            navigate('/');
        }
    }, []);

    const login = () => {
        if (!username || !username.includes('@') || !username.includes('.') || username.length < 6) {
            setError('Identifiant non valid'); return;
        }

        if (!password || password.length < 6) {
            setError('Mot de passe au moin 6 caractères'); return;
        }
        setError('');
        setLoading(true);
        service.login(username, password)
            .then(res => {
                setLoading(false);
                storageService.saveAuth(res.data);
                window.location.href = `/`;
            }).catch(err => {
                setLoading(false);
                setError('Mauvais email/mot de pass');
            });
    }

    const handleEnter = event => {
        if (event.keyCode === 13) login();
    }

    const inputStyle = { background: '#EDEDED', fontSize: 16, borderRadius: 10, border: 'none' };
    return (
        <Layout style={{ justifyContent: "center", alignContent: "center" }}
        >
            <Box
                justify="center"
                as="main"
                flex
                overflow="auto"
                width="large"
                direction="column"
                alignContent="center"
                alignSelf="center"
            >
                <Box width="medium" alignSelf="center" justify="center" alignContent="center" gap="medium">
                    <Image src={logo || mainLogo} />

                    <Text size="small" style={{ color: '#dc3545' }} textAlign="center">{error}</Text>
                    <Box width="large" gap="small">
                        <TextInput
                            placeholder="Email "
                            onChange={event => setUserName(event.target.value)}
                            style={inputStyle}
                        />
                        <TextInput
                            placeholder="Mot de passe "
                            type="password"
                            onKeyDown={handleEnter}
                            onChange={event => setPassword(event.target.value)}
                            style={inputStyle}
                        />
                        <Box width="small" alignSelf="center">
                            <PrimaryButton
                                onClick={login} disabled={loading}
                                label="Connexion" color='#fff'
                            />
                        </Box>
                        <Box width="large" align="center" justify="center">
                            <Link to="/forgot-password">
                                <Text style={{ color: '#707070', fontSize: 14 }} textAlign="center">J'ai oublié mon mot de passe</Text>
                            </Link>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Layout>
    );
}

export default LoginPage;